import React, { useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classNames from "classnames/bind";
import styles from "../../styles/Banner.module.scss";
import useWindowSize from "../../hooks/useWindowSize";

import dynamic from "next/dynamic";

const Image = dynamic(() => import("next/image"), { ssr: false });

function BannerKitakerja({ bannerList = [] }) {

  const cx = classNames.bind(styles);

  const { width } = useWindowSize();

  const isMobile = width < 578;

  const bannerSetting = useMemo(() => {
    let defaultSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 1500,
      autoplaySpeed: 5000,
      autoplay: true,
      infinite: true,
    };

    if (isMobile) {
      defaultSettings = {
        ...defaultSettings,
        infinite: false,
        centerMode: true,
        centerPadding: 16,
      };
    }

    return defaultSettings;
  }, [isMobile]);

  return (
    <div className="container p-0 p-sm-2">
      <div className={cx("sliderContainer", "col-12")}>
        <Slider {...bannerSetting} className="overflow-hidden">
          {bannerList.map((row, index) => (
            <div key={index} className={cx("imageContainer")}>
              <div className="rounded overflow-hidden">
                <a href={row.redirect_url} target="blank">
                  <Image
                    src={
                      width < 768
                        ? row?.mobile_file?.sizes?.large
                        : row?.file?.sizes?.large
                    }
                    layout="responsive"
                    alt={`lowongan kerja Medan-banner-${index}`}
                    width={width < 768 ? 1430 : 1920}
                    height={width < 768 ? 476 : 480}
                  />
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default BannerKitakerja;

import Head from "next/head";
import styles from "../styles/Home.module.css";
import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { encode } from "../functions/linkFormat";
import PaperWithGradient from "../components/PaperWithGradient";
import { getApi } from "../functions/API";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import parse from "autosuggest-highlight/parse";
// import match from "autosuggest-highlight/match";
// import useDebounce from "../hooks/debounce";
import Loading from "@pmberjaya/lib/build/Loading";
// import Button from "@pmberjaya/kitakerja-ui/build/Components/Button";
// import { useLazyQuery } from "@apollo/client";
// import { REQUEST_SUGGESTIONS } from "../graphql/ListingJob/query";
// import { useToast } from "@pmberjaya/kitakerja-ui/build/Components/Toast";
import BannerKitakerja from "../components/BannerKitakerja/Index";
// import Card from "@pmberjaya/kitakerja-ui/build/Components/Card";
import { initializeApollo } from "../apollo";
import { gql } from "@apollo/client";
import Link from "next/link";
// import VaksinInformation from "../components/BannerVaksin";

const jelajahiKitakerja = [
  {
    name: "Akutansi & Keuangan",
    icon: "/icons/finance.svg",
    link: "/list/job/akuntansi-keuangan",
  },
  {
    name: "Teknologi Informasi",
    icon: "/icons/coding.svg",
    link: "/list/job/it",
  },
  {
    name: "Penjualan / Pemasaran",
    icon: "/icons/megaphone.svg",
    link: "/list/job/marketing",
  },
  {
    name: "Sains",
    icon: "/icons/Sains.svg",
    link: "/list/job/research",
  },
  {
    name: "Pendidikan / Pelatihan",
    icon: "/icons/book.svg",
    link: "/list/job/guru",
  },
  {
    name: "Seni / Media",
    icon: "/icons/Art.svg",
    link: "/list/job/design",
  },
  {
    name: "Layanan Kesehatan",
    icon: "/icons/Health.svg",
    link: "/list/job/health",
  },
  {
    name: "Lainnya...",
    icon: "/icons/Others.svg",
    link: "/list/job",
  },
];

// const fiturKami = [
//   {
//     label: "Informasi Lowongan Lengkap",
//     description:
//       "Dapatkan informasi lowongan kerja yang lengkap sesuai dengan gaji, lokasi pekerjaan, hingga informasi mengenai perusahaan.",
//   },
//   {
//     label: "Identitas Lengkap",
//     description:
//       "Lengkapi identitas anda pada halaman profil anda agar selalu dapat lowongan pekerjaan yang sesuai.",
//   },
//   {
//     label: "Referensi Pekerjaan",
//     description:
//       "Masuk ke halaman Job Preferences untuk mengisi data lowongan pekerjaan yang anda inginkan sehingga semua daftar lowongan rekomendasi akan muncul sesuai data lowongan yang anda inginkan.",
//   },
// ];

const kitakerjaUntukPerusahaan = [
  {
    label: "Iklan Lowongan Anda",
    description:
      "Dengan pasang iklan lowongan di Kitakerja, Anda memiliki kesempatan 8x lebih besar untuk mendapatkan kandidat yang tepat.",
  },
  {
    label: "Seleksi pelamar dengan mudah dan efisien",
    description:
      "Mempermudah proses seleksi dan rekrutmen Anda ketika menerima lamaran yang banyak. Semuanya dapat diakses secara gratis.",
  },
  {
    label: "Cari pelamar yang tepat",
    description:
      "Anda dapat memasang iklan lowongan di kitakerja.com kapan saja, tapi tidak hanya itu. Anda juga dapat menggunakan fitur Talent Search untuk mencari jutaan database pelamar kami.",
  },
];

const settings = {
  className: "center",
  centerMode: true,
  centerPadding: "20px",
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function InformationSection(props) {
  return (
    <ul className={styles.ul}>
      {props.data.map((row, index) => (
        <li key={index} className={styles.li}>
          <div className={styles.circle} />
          <div style={{ marginLeft: "20px", textAlign: "justify" }}>
            <h4 className={styles.informationTitle}>{row.label}</h4>
            <small>{row.description}</small>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default function Home({ banners }) {
  // const [searchValue, setSearchValue] = useState("");
  const [featureListing, setFeatureListing] = useState();
  // const searchValueDebounce = useDebounce(searchValue, 1000);
  // const [open, setOpen] = useState(false);
  // const { toast } = useToast();

  // const [getSuggestions, { data, error, loading }] =
  //   useLazyQuery(REQUEST_SUGGESTIONS);

  // const handleSearch = (event) => {
  //   event.preventDefault();
  //   window.location.href = `${process.env.BASE_URL}/list/job/${encode(
  //     searchValue
  //   )}`;
  // };

  async function getRecomendationListing() {
    const data = await getApi(`/api/main/job/gctsearch?mode=featured`);
    setFeatureListing(data);
  }

  useEffect(() => {
    getRecomendationListing();
  }, []);

  // useEffect(() => {
  //   if (error) {
  //     toast({
  //       message: error.message,
  //       variant: "danger",
  //     });
  //   }
  // }, [error]);

  // useEffect(() => {
  //   if (searchValueDebounce) {
  //     if (searchValueDebounce !== " ") {
  //       getSuggestions({
  //         variables: {
  //           keyword: searchValueDebounce,
  //         },
  //       });
  //     }
  //   }
  // }, [searchValueDebounce]);

  const locationText = (rowData) => {
    const filter = [];
    rowData.companies_location_has_job.forEach((row) => {
      filter.push(
        row.companies_location.second_level_administrative_divisions.name
      );
    });
    return filter.toString();
  };

  return (
    <div>
      <Head>
        <title>
          KitaKerja | Temukan Informasi Lowongan Kerja di Medan, Indonesia
        </title>
        <link rel="canonical" href={process.env.BASE_URL} />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Situs lowongan kerja dan peluang karir terlengkap di Indonesia. Temukan informasi lowongan kerja di Medan dan kesempatan karir impian sesuai dengan lokasi, posisi, pendidikan serta gaji yang anda inginkan di KitaKerja.com."
        />
        <meta
          property="og:title"
          content="KitaKerja | Temukan Informasi Lowongan Kerja di Medan, Indonesia"
        />
        <meta
          property="og:description"
          content="Situs lowongan kerja dan peluang karir terlengkap di Indonesia. Temukan informasi lowongan kerja di Medan dan kesempatan karir impian sesuai dengan lokasi, posisi, pendidikan serta gaji yang anda inginkan di KitaKerja.com."
        />
        <link rel="icon" sizes="32x32" href="/favicon-32x32.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />

        <meta property="og:type" content="website" />
        {/*<meta*/}
        {/*  property="og:image"*/}
        {/*  itemProp="image"*/}
        {/*  content="/images/ogImage.png"*/}
        {/*/>*/}
        <meta property="og:image" content="image/ogImage.png" />
        <meta property="og:url" content={process.env.BASE_URL} />
        <meta property="og:site_name" content="KitaKerja" />
        <meta
          name="google-site-verification"
          content="XpF8WxsDpfN5wwQ8VmpAbySahQqqG9zxjK_C8WFGlWs"
        />
      </Head>

      {/* Search bar comment */}
      {/* <div className="mb-3 mb-md-4">
        <Card className="p-3 w-100 align-items-center">
          <div className="container">
            <form onSubmit={handleSearch}>
              <div className="d-flex justify-content-center">
                <Autocomplete
                  id="asynchronous-demo"
                  className={styles.autoComplete}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  getOptionSelected={(option, value) => {
                    option === value;
                  }}
                  getOptionLabel={(option) => option}
                  options={data?.job?.suggestion || []}
                  loading={loading}
                  renderOption={(option) => {
                    const matches = match(option, searchValue);
                    const parts = parse(option, matches);
                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{ fontWeight: part.highlight ? 700 : 400 }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                  onChange={(event, newValue) => {
                    setSearchValue(newValue);
                  }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        {...params.inputProps}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                        value={searchValue}
                        className={styles.searchBox}
                        type="text"
                        name="search"
                        autoComplete="off"
                        placeholder="Cari pekerjaan, perusahaan, tempat..."
                      />
                    </div>
                  )}
                />

                <Button
                  className={styles.searchBtn}
                  type="submit"
                  size="medium"
                >
                  Cari
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </div> */}
      <BannerKitakerja bannerList={banners} />
      <div className={styles.section}>
        <div className={`${styles.sectionContainer} container`}>
          <div className="row">
            <div className="col-12">
              <h1 className={styles.section_title}>
                CARI LOWONGAN KERJA MEDAN
              </h1>
            </div>
            <div className="col-12 mb-4">
              <h2 className={styles.section_desc}>
                Temukan informasi lowongan kerja Medan terbaru
              </h2>
            </div>

            {jelajahiKitakerja.map((row, index) => (
              <div key={index} className="col-4 col-sm-3">
                <div className={styles.iconWidth}>
                  <a href={`${row.link}`}>
                    <Image
                      src={row.icon}
                      alt={row.name}
                      width={140}
                      height={140}
                      loading="lazy"
                    />
                  </a>
                </div>
                <h4 style={{ marginTop: "5px" }} className="fs-6">
                  <b>{row.name}</b>
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={`${styles.sectionContainer} container`}>
          <div className="row">
            <div className="col-12">
              <h2 className={styles.section_title}>LOWONGAN POPULER</h2>
            </div>
            <div className={`${styles.itemContent} col-12`}>
              {featureListing ? (
                <div style={{ width: "90%", maxWidth: "90%" }}>
                  <Slider {...settings}>
                    {featureListing?.data?.map((row, index) => (
                      <a
                        key={`${row.id}#${index}`}
                        href={`/listing/detail/job/${encode(
                          row.companies.name
                        )}-${encode(row.title)}/${row.id}?clientEvent=${
                          featureListing?.client_event_request_id
                        }`}
                        className="text-dark"
                      >
                        <div style={{ padding: "5px" }}>
                          <PaperWithGradient
                            style={{
                              paddingTop: "unset",
                              borderRadius: "8px",
                              margin: "0.5rem",
                            }}
                          >
                            <div style={{ padding: "10px" }}>
                              <Image
                                src={row.companies.logo}
                                alt={row.companies.name}
                                objectFit="contain"
                                width="20%"
                                height="20%"
                                layout="responsive"
                                loading="lazy"
                              />

                              <p
                                className={styles.longText}
                                style={{
                                  fontSize: "calc(0.95625rem + 0.075vw)",
                                }}
                              >
                                <b>{row.title}</b>
                              </p>
                              <p className={styles.longText}>
                                {row.companies.name}
                              </p>
                              <p className={styles.longText}>
                                {locationText(row)}
                              </p>
                            </div>
                          </PaperWithGradient>
                        </div>
                      </a>
                    ))}
                  </Slider>
                </div>
              ) : (
                <Loading />
              )}
            </div>
            <div
              className={`${styles.itemContent} col-12`}
              style={{ paddingTop: "1rem" }}
            >
              <Link href={`/list/job`}>
                <a className="btn btn-primary">Lihat lainnya</a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={`${styles.sectionContainer} container`}>
          <div className="row">
            <div className="col-12">
              <h2 className={styles.section_title}>
                KITAKERJA UNTUK PERUSAHAAN
              </h2>
            </div>
            <div
              className="col-lg-6 col-12"
              style={{
                padding: "1rem",
                paddingTop: "1.5rem",
                display: "inline-grid",
                alignItems: "center",
              }}
            >
              <a href={process.env.COMPANY_BASE_URL} target="blank">
                <Image
                  src={"/images/companyPromotion.png"}
                  alt="lowongan kerja Medan"
                  width="100%"
                  height="58px"
                  layout="responsive"
                  loading="lazy"
                />
              </a>
            </div>
            <div
              className={`${styles.itemContent} col-lg-6 col-12`}
              style={{
                padding: "1rem",
                paddingTop: "1.5rem",
                display: "inline-grid",
                alignItems: "center",
              }}
            >
              <div
                className={styles.information}
                style={{ paddingTop: "1rem" }}
              >
                <InformationSection data={kitakerjaUntukPerusahaan} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function getServerSideProps() {
  let data = [];

  const client = initializeApollo();

  try {
    const {
      data: {
        banner: { banners },
      },
    } = await client.query({
      query: gql`
        query HomeBanner {
          banner {
            banners {
              id
              redirect_url
              file {
                id
                sizes {
                  large
                }
              }
              mobile_file {
                id
                sizes {
                  large
                }
              }
            }
          }
        }
      `,
    });

    data = banners;
  } catch (error) {
    console.log(error);
  }

  return {
    props: { banners: data },
  };
}

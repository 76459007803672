import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const instance = axios.create( {
    baseURL: process.env.BACKEND_URL,
    headers: {
      Authorization: `Bearer ${cookies.get("access_token")}`,
      "x-client-id": cookies.get("KITAKERJA_CLIENT_ID"),
    }
});

export const serverInstance = (headerProps) =>
  axios.create({
    baseURL: process.env.BACKEND_URL,
    headers: { ...headerProps },
  });

import React from "react";
import styles from "./gradient.module.css";
import Card from "@pmberjaya/kitakerja-ui/build/Components/Card";

function PaperWithGradient(props) {
  return (
    <Card {...props}>
      <div className={styles.gradient} />
      {props.children}
    </Card>
  );
}

export default PaperWithGradient;

import { instance } from "../utils/axiosInstance";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export async function getApi(link, setErrorMessage) {
  let value;
  try {
    const response = await instance.get(link);

    if (response.status === 200 && response.data.status) {
      value = response.data.data;
    } else {
      if (setErrorMessage) {
        setErrorMessage(response.data.messages[0]);
      } else {
        value = response.data;
      }
    }
  } catch (error) {
    value = error;
    if (error?.response?.request?.status === 401) {
      window.location.href = `${process.env.BASE_URL}/tokenexpired`;
      localStorage.clear();
      cookies.remove("KITAKERJA_CLIENT_ID", { path: "/" });
      cookies.remove("member", { path: "/" });
      cookies.remove("name", { path: "/" });
      cookies.remove("member_id", { path: "/" });
      cookies.remove("isLogin", { path: "/" });
      cookies.remove("access_token", { path: "/" });
    }
  }
  return value;
}

export async function postApi(link, param, setErrorMessage, setSuccessMessage) {
  let value;
  try {
    const response = await instance.post(link, param);

    if (response.status === 200 && response.data.status) {
      value = response.data.data;
      setSuccessMessage("Success");
    } else {
      setErrorMessage(response.data.messages[0]);
    }
  } catch (error) {
    value = error;
    if (error?.response?.request?.status === 401) {
      window.location.href = `${process.env.BASE_URL}/tokenexpired`;
      localStorage.clear();
      cookies.remove("KITAKERJA_CLIENT_ID");
      cookies.remove("member");
      cookies.remove("name");
      cookies.remove("member_id");
      cookies.remove("isLogin");
      cookies.remove("access_token");
    } else {
      // setErrorMessage(error?.response?.data?.messages[0]);
      const message = error?.response?.data?.errors;
      if (message) {
        Object.keys(message).map((row) => {
          setErrorMessage(message[row][0]);
        });
      }
    }
  }
  return value;
}
